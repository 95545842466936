import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
//import Media from "react-media"
//import StickyBox from "react-sticky-box"

import Header from "components/Header"
import Footer from "components/Footer"
import "components/layout.scss"

import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faHistory,
  faListUl,
  faTag,
  faFont,
  faImage,
  faVrCardboard,
  faCode,
  faHome,
  faCloud,
  faTable,
  faChess,
  faBook,
  faTools,
  faHandshake,
  faMagic,
  faCubes,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons"
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons"
import {
  faTwitter,
  faGithub,
  faReact,
  faVuejs,
  faBehanceSquare,
  faApple,
  faSass,
  faCss3,
  faWordpress,
  faAdobe,
  faGulp
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faHistory,
  faListUl,
  faTag,
  faFont,
  faImage,
  faVrCardboard,
  faCode,
  faHome,
  faCloud,
  faTable,
  faChess,
  faBook,
  faTools,
  faHandshake,
  faMagic,
  faCubes,
  faArrowDown,
  faCalendarAlt,
  faTwitter,
  faGithub,
  faReact,
  faVuejs,
  faBehanceSquare,
  faApple,
  faSass,
  faCss3,
  faWordpress,
  faAdobe,
  faGulp
)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Header />
        <main className="section is-main">
          <div className="inner is-space-half">{children}</div>
        </main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
