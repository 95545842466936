import React from "react"

const pkg = require("root/package.json")
//const pjt = require("root/project.json")

const dateNowYear = new Date().getFullYear()

const Footer = props => (
  <footer className="section is-footer">
    <div className="inner-soft">
      <p className="text is-font-en-modern is-center is-space-right-quarter is-dark-4 is-sm">
        <span className="text">©</span>
        <a className="text is-link-reverse" href={pkg.author.url}>
          {pkg.author.name}
        </a>
        <span className="text">{dateNowYear}</span>
      </p>
    </div>
  </footer>
)

export default Footer
